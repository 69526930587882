/**
 * Alerts displays header fixed elements interactions if alert block presents
 * @param {*} $
 * @returns {init} Determine and run if alerts present
 */

const alerts = (() => {
  function init() {
    const $window = $(window);
    const $header = $('.header');
    const $controls = $('.fab-links-sidepanel');
    const $alert = $('.alert');
    const $body = $('body');
    const $alertClose = $('.alert__close');
    let ALERT_HEIGHT = $alert.outerHeight();

    const scrollFunc = () => {
      if ($window.scrollTop() > ALERT_HEIGHT) {
        $body.addClass('alert-scrolled');
      } else {
        $body.removeClass('alert-scrolled');
      }
    };

    const setStyles = () => {
      const windowWidth = $window.width();
      const getNavTop = () => {
        if (windowWidth > 1024) {
          return ALERT_HEIGHT + 133;
        }
        return ALERT_HEIGHT + 100;
      };
      $controls.css({ top: getNavTop }).addClass('js-show');
    };

    const resizeFunc = () => {
      ALERT_HEIGHT = $alert.outerHeight();
      setStyles();
    };

    const closeAlert = () => {
      $header.css({ top: '' });
      $controls.css({ top: '' });
      $body.removeClass('alert-scrolled has-alert');
      $window.off('resize', resizeFunc);
      $window.off('scroll', scrollFunc);
      $alert.slideUp();
    };

    if ($alert.length !== 0) {
      $body.addClass('has-alert');
      setStyles();
      $window.on('resize', resizeFunc);
      $window.on('scroll', scrollFunc);
      $alertClose.click(closeAlert);
    }
  }

  return {
    init,
  };
})();

export default alerts;
