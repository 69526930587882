/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').slick({
      dots: true,
      infinite: false,
      mobileFirst: true,
      slidesToShow: 1,
      responsive: [
        {
          breakpoint: 699,
          settings: {
            variableWidth: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            variableWidth: true,
          },
        },
      ],
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
