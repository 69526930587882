/**
 * Search displays search form in header
 * @param {*} $
 * @returns {init} Determine and run if search present
 */

const searchSlide = (() => {
  function init() {
    const $header = $('.header');
    const $searchOpener = $('.search-opener');
    const $searchForm = $('.header .search-form');
    const $navBlock = $('.nav-block__holder');
    const $submitBtn = $searchForm.find('button[type="submit"]');
    const $searchInput = $searchForm.find('input[type="search"]');
    const SEARCH_EXPANDED_CLASS = 'search-expanded';
    const NAV_EXPANDED_CLASS = 'nav-expanded';

    $searchOpener.click(() => {
      $header.toggleClass(SEARCH_EXPANDED_CLASS);
      $searchForm.slideToggle();
      if ($header.hasClass(SEARCH_EXPANDED_CLASS)) {
        $searchOpener.attr('aria-expanded', 'true');
        $searchForm.attr('aria-hidden', 'false');
      } else {
        $searchOpener.attr('aria-expanded', 'false');
        $searchForm.attr('aria-hidden', 'true');
      }
      if ($(window).width() < 768 && $header.hasClass(SEARCH_EXPANDED_CLASS)) {
        $header.css('padding-top', '61px');
      } else {
        $header.css('padding-top', '0');
      }
      if ($header.hasClass(NAV_EXPANDED_CLASS)) {
        $header.removeClass(NAV_EXPANDED_CLASS);
        $navBlock.slideToggle();
      }

      if ($(window).width() <= 1024) {
        if ($header.hasClass(SEARCH_EXPANDED_CLASS)) {
          $('body,html').animate(
            {
              scrollTop: 0,
            },
            300,
          );

          $('body,html').addClass('js-fixed');
        } else {
          $('body,html').removeClass('js-fixed');
        }
      }
    });

    $submitBtn.on('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey === false &&
        $header.hasClass(SEARCH_EXPANDED_CLASS)
      ) {
        e.preventDefault();
        $searchOpener.focus();
      }
    });

    $searchOpener.on('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey === true &&
        $header.hasClass(SEARCH_EXPANDED_CLASS)
      ) {
        e.preventDefault();
        $submitBtn.focus();
      }
    });

    $searchOpener.on('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey === false &&
        $header.hasClass(SEARCH_EXPANDED_CLASS)
      ) {
        e.preventDefault();
        $searchInput.focus();
      }
    });

    $searchInput.on('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey === true &&
        $header.hasClass(SEARCH_EXPANDED_CLASS)
      ) {
        e.preventDefault();
        $searchOpener.focus();
      }
    });
  }

  return {
    init,
  };
})();

export default searchSlide;
