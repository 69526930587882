/**
 * Nav displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if nav present
 */

const navSlide = (() => {
  function init() {
    const $header = $('.header');
    const $navOpener = $('.nav-opener');
    const $navBlock = $('.nav-block__holder');
    const $searchForm = $('.header .search-form');
    const $lastFocusedLink = $($('.fab-links a')[$('.fab-links a').length - 1]);
    const $primaryNavLinks = $('.main-nav > ul > li > a');
    const NAV_EXPANDED_CLASS = 'nav-expanded';
    const SEARCH_EXPANDED_CLASS = 'search-expanded';

    function openMenu() {
      $header.toggleClass(NAV_EXPANDED_CLASS);
      $navBlock.slideToggle();
      if ($header.hasClass('nav-expanded')) {
        $navOpener.attr('aria-expanded', 'true');
        $navBlock.attr('aria-hidden', 'false');
      } else {
        $navOpener.attr('aria-expanded', 'false');
        $navBlock.attr('aria-hidden', 'true');
      }

      if ($header.hasClass(SEARCH_EXPANDED_CLASS)) {
        $header.css({ 'padding-top': 0 });
        $header.removeClass(SEARCH_EXPANDED_CLASS);
        $searchForm.slideToggle();
      }

      if ($(window).width() <= 1024) {
        if ($header.hasClass(NAV_EXPANDED_CLASS)) {
          $('body,html').animate(
            {
              scrollTop: 0,
            },
            300,
          );

          $('body,html').addClass('js-fixed');
        } else {
          $('body,html').removeClass('js-fixed');
        }
      }
    }

    function setAriaState() {
      if ($(window).width() > 1024) {
        $navBlock.removeAttr('aria-hidden');
      } else {
        $navBlock.attr('aria-hidden', 'true');
      }
    }

    $(window).on('resize load orientationchange', setAriaState);

    $navOpener.click(openMenu);
    $('.header .overlay').click(openMenu);

    $primaryNavLinks.on('keydown focus', e => {
      if ($(window).width() > 1024) {
        if ((e.keyCode === 9 && e.shiftKey === false) || e.type === 'focus') {
          $('.main-nav')
            .find('.js-hover')
            .removeClass('js-hover');
          $(e.target)
            .parent()
            .addClass('js-hover');
        }
      }
    });

    $lastFocusedLink.on('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey === false &&
        window.innerWidth <= 1024
      ) {
        e.preventDefault();
        $navOpener.focus();
      }
    });

    $navOpener.on('keydown', e => {
      if (
        e.keyCode === 9 &&
        e.shiftKey === true &&
        $header.hasClass(NAV_EXPANDED_CLASS)
      ) {
        e.preventDefault();
        $lastFocusedLink.focus();
      }
    });
  }

  return {
    init,
  };
})();

export default navSlide;
